<template>
  <div class="w-full relative flex flex-wrap py-4" :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <div class="absolute w-full pin-x flex justify-center content-start" style="z-index: -1">
      <!-- <card-loading-indicator
        v-for="n in 6"
        :key="n"
        :is-loading="isLoading"
        :is-ready="isReady"
      ></card-loading-indicator> -->
      <span class="text-2xl font-form-a1 uppercase mt-10" v-if="isLoading">{{ vueTranslations.loading }}</span>
    </div>
    <div v-if="items.length > 0 && viewMode === 'list' && !isLoading" item-selector=".item" class="flex flex-wrap" style="min-width: 100%;">
        <!-- <category-search-item
          :item="item"
          :is-loading="isLoading"
          :section-type="sectionType"
        ></category-search-item> -->
      <div v-for="(item, key, index) in items" :key="key" class="card w-full md:w-1/2 lg:w-1/4 flex-shrink-0 px-2 max-w-card mb-12 md:mb-16">
        <div class="flex flex-col h-full overflow-hidden">
          <div class="block relative w-full">
            <a :href="item.url" :title="item.title">
              <figure class="w-full pb-aspect130 relative mb-2 md:mb-5 bg-coral">
                <template v-if="!!item.cardImage || !!item.image">
                  <img v-if="item.cardImage[0]" :src="item.cardImage[0].url" alt="" class="lazyload absolute top-0 left-0 w-full h-full object-cover" />
                  <img v-else :src="!!item.image[0] ? item.image[0].url : '/assets/img/placeholder.svg'" alt="" class="lazyload absolute top-0 left-0 w-full h-full object-cover" />
                  <div class="absolute top-0 left-0 z-0 w-full h-full block opacity-40 bg-black"></div>
                </template>
                <img
                  v-else
                  src="/assets/img/search-placeholder.svg"
                  alt=""
                  class="lazyload absolute top-0 left-0 z-0 w-full h-full block object-contain p-2"
                />
              </figure>
            </a>
            <div class="z-10">
              <p class="font-form-a1 text-xl text-white absolute left-0 top-0 pt-1 pl-4 md:pt-2 md:pl-5 mb-0 tracking-normal">
                <span v-if="item.startDate && item.endDate">
                  {{ moment(item.startDate).format('D.M.') + ' - ' + moment(item.endDate).format('D.M.YYYY')}}
                </span>
                <span v-else-if="item.startDate">
                  {{ moment(item.startDate).format('D.M.YYYY')}}
                </span>
              </p>
            </div>    
          </div>
          
          <a :href="item.url" :aria-label="'Read more about ' + 'item.title'" class="block">
            <h3 class="font-form-a1 text-xl md:text-4xl mb-2 md:mb-4">{{ item.title }}</h3>
            <div v-if="!!item.operator && item.operator[0]" class="flex items-center mb-4">
              <span class="font-form-a1 text-base md:text-lg">{{ item.operator[0].title }}</span>
            </div>
            <div v-if="!!item.primaryExperienceType" class="flex items-center mb-4">
              <span class="font-form-a1 text-base md:text-lg text-silverChalice">
                <span v-for="(category, categoryKey) in item.primaryExperienceType" :key="categoryKey">
                  {{ category.title }}{{(index != Object.keys(item.primaryExperienceType).length - 1) ? '' : ', '}}
                </span>
              </span>
            </div>
          </a>
          <div class="flex justify-between mt-auto">
            <a 
              :href="item.url" 
              class="block button flex items-center self-start justify-center button--white text-black text-1xl font-form-a1" 
              :class="{ 'mr-2': item.ticketLink && lastSegment != vueTranslations.past }"
              style="white-space: nowrap;text-align: center;border:1px solid #000;"><span class="sr-only">{{ vueTranslations.readMore }}</span>{{ vueTranslations.readMore }}</a>
            <!-- Show ticket link if not past experience -->
            <a 
              v-if="item.ticketLink && lastSegment != vueTranslations.past"
              :href="item.ticketLink" 
              target="_blank" 
              class="button flex items-center self-start justify-center button-black text-white text-1xl font-form-a1 relative" 
              style="flex-grow: 1;white-space: nowrap;text-align: center;padding:11px 16px 15px 16px;"
            >
              <span class="sr-only">{{ vueTranslations.buyTicketFromProvider }}</span>
              {{ vueTranslations.buyTickets }}
              <span class="ml-2 relative" style="top: 2px;">
                <svg width="13px" height="18px" class="fill-current" viewBox="0 0 13 18">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g fill="#FFFFFF" fill-rule="nonzero">
                      <path d="M12,0 L12,16 L8.51876872,16 L8.37704804,15.3005854 C8.14016877,14.1315761 7.14720045,13.2913969 6,13.2913969 C4.98495042,13.2913969 4.08063462,13.951606 3.72971592,14.9194361 L3.68711866,15.0473904 L3.6529429,15.1690818 L3.62295247,15.3005829 L3.48123128,16 L0,16 L0,0 L12,0 Z M10.3026667,1.76069565 L1.69666667,1.76069565 L1.69666667,7.85878261 L3.27676764,7.85938945 L3.27676764,9.62072155 L1.69666667,9.62017391 L1.69666667,14.2386087 L2.16466667,14.237913 L2.22785657,14.0830065 C2.78515934,12.7760039 3.94049868,11.8352166 5.30906088,11.5910356 L5.47428719,11.5650988 L5.6476568,11.5457456 L5.82847108,11.5338547 L6,11.5300648 C7.63157632,11.5300648 9.10204521,12.531532 9.76693504,14.0723326 L9.83466667,14.237913 L10.3026667,14.2386087 L10.3026667,9.62017391 L8.58585859,9.62072155 L8.58585859,7.85938945 L10.3026667,7.85878261 L10.3026667,1.76069565 Z M7.39797977,7.85938945 L7.39797977,9.62072155 L4.46464646,9.62072155 L4.46464646,7.85938945 L7.39797977,7.85938945 Z"></path>
                    </g>
                  </g>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <template v-else-if="items.length > 0 && viewMode === 'calendar' && !isLoading">
      <section :key="JSON.stringify(eventsByDate)" v-if="_.size(eventsByDate) > 0" class="mb-4 md:mb-8 lg:mx-5-5 w-full calendar-dates-list">
        <template v-for="(date, key, index) in eventsByDate">
          <calendar-date v-if="showDate(date)" :date="date" :first="index === 0" :last="index === _.size(eventsByDate) - 1" :key="key" />
        </template>
      </section>
    </template>
    <transition name="fadeIn" mode="in-out">
      <template v-if="!isLoading && items.length === 0">
        <h4 class="heading-6 mb-6 leading-slight text-xl font-normal text-grey-dark max-w-2xs mx-auto py-8 text-center">{{ vueTranslations.notFound }}</h4>
      </template>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue';

// import CategorySearchItem from './CategorySearchItem.vue';
import CardLoadingIndicator from './CardLoadingIndicator.vue';
import { setTimeout } from 'timers';
import moment from 'moment'
import CalendarDate from '../calendar/CalendarDate.vue';
import _ from 'lodash'

export default {
  name: 'CategorySearchList',
  data() {
    return {
      vueTranslations
    }
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: String,
      default: 'list'
    },
    eventsByDate: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    // CategorySearchItem,
    CardLoadingIndicator,
    CalendarDate
  },
  computed: {
    _() {
      return _;
    },
    lastSegment: function() {
      return location.href.substring(location.href.lastIndexOf('/') + 1);
    }
  },
  watch: {
    items: function(val, oldVal) {
    },
    isLoading: function(val, oldVal) {
      if(val === false) {
      }
    }
  },
  methods: {
    listMinHeight: function() {
      let rowHeight = '280';
      let rowAmount = '3';
      if ( window.innerWidth < 480 ) {
        rowHeight = '280';
        rowAmount = '3';
      } else if ( window.innerWidth < 780 ) {
        rowHeight = '280';
        rowAmount = '3';
      }
      return rowAmount * rowHeight + 'px';
    },
    showDate: function(date) {
      let showDate = false;
      _.each(date.events, (event) => {
        if (event.irregularShowTimes) {
          _.forEach(event.irregularShowTimes, (showTime) => {
            if (moment(showTime.date).isSame(moment(date.date), 'd')) {
              showDate = true;
            }
          })
        }
      })
      return showDate;
    }
  }
};
</script>
